import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Auth } from "../model/auth";
import { LocalStorageService } from "../utils/storage.service";
import { environment } from "src/environments/environment";
import { ResetTokem } from "../model/reset-tokem";
import { ResponseSuccess } from "../model/response-success";


@Injectable({
    providedIn:"root"
})

export class AuthService{
    
   BASE_URL = environment.BASE_URL;

    constructor(
        private http: HttpClient, 
        private localStorage: LocalStorageService,
    ){}

     authFx(auth:Auth){
        return this.http.post<Auth>(`${this.BASE_URL}auth/login`, {email:auth.email, password:auth.password})
    }

    logout(id_tokem: number){
        return this.http.get<ResponseSuccess>(`${this.BASE_URL}auth/logout/${id_tokem}`)
    }

    checkEmail(email:string){
       return this.http.post<any>(`${this.BASE_URL}auth/firstaccess`,{email:email}) 
    }

    checkCod(auth:Auth){
        return this.http.post<ResetTokem>(`${this.BASE_URL}auth/check/profile`, {email:auth.email, tokem: auth.tokem});
    }

    registerPassword(user_fk:number, password:string, reset_id:number){
        return this.http.put<any>(`${this.BASE_URL}auth/create/password`, {user_fk:user_fk, password:password, reset_id:reset_id});
    }

   async isLoggedIn(){
     const auth = await this.localStorage.getlocalStorageAuth();
     if(auth){
        return auth
     }else{
        return false
     }
    }

    saveProfile(data:any){
        return this.http.post<ResponseSuccess>(`${this.BASE_URL}profile/update`, {...data})
    }   

}