import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "../auth.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardChild implements CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {

        const isauth = this.authService.isLoggedIn().then(res => {
            if (res) {
                return true;
            } else {
                return this.router.navigate(['']);
            }
        }).catch(err => {
            return err
        })

        return isauth
    }
}
