
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <p-toast></p-toast>
   
    <div class="col-12 sm:col-10 md:col-8 lg:col-6 xl:col-4 flex flex-column align-items-center justify-content-center">
        <div class="grid">
            <div class="col-4">
                <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'fx-logo' : 'fx-logo'}}.png"
                 alt="Sakai logo" 
                 class="mb-2 w-12rem  sm:w-16rem  md:w-16rem lg:w-16rem  xl:w-16rem  flex-shrink-0">                
            </div>
        </div>

        <div style="border-radius:56px;  padding:0.3rem;  background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-4 px-5 sm:px-8" style="border-radius:53px">
                <div class="grid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <div class="text-center mb-0">
                            <div class="text-900 text-3xl font-medium mb-3">Bem vindo!</div>
                            <span class="text-600 font-medium">Faça login para continuar</span>
                        </div>
                    </div>

                    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <input id="email1" 
                        class="col-12 mb-5 p-2 sm:p-3 md:p-3 lg:p-3 xl:p-3" 
                        type="text" placeholder="E-mail"  [(ngModel)]="authLogin.email" pInputText >
    
                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <input id="password1" type="password" 
                            class="col-12 mb-5 p-2 sm:p-3 md:p-3 lg:p-3 xl:p-3" 
                            [(ngModel)]="authLogin.password"
                            placeholder="Senha" pInputText />
    
                       
                        <button 
                        pButton 
                        pRipple 
                        label="Login"
                        [loading]="loading"
                        class="col-12 text-xl p-button-warning p-2 sm:p-3 md:p-3 lg:p-3 xl:p-3"
                        (click)="auth()"></button>
                        
                        <div
                        class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 flex align-items-center justify-content-between mb-5 gap-5">
                        <a class="flex align-items-center justify-content-center" routerLink="first"
                            class="font-medium no-underline ml-2 text-right cursor-pointer"
                            style="color: var(--primary-color)">Primeiro Acesso</a>

                        <a class="flex align-items-center justify-content-center" routerLink="first"
                            class="font-medium no-underline ml-2 text-right cursor-pointer"
                            style="color: var(--primary-color)">Esqueceu a senha?</a>
                    </div>

                        </div>

                </div>

            </div>
        </div>
    </div>

</div>
