import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './portal-fx/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { LoginComponent } from './portal-fx/components/auth/login/login.component';
import { AuthGuardChild } from './portal-fx/service/auth-guard/auth-guard.child';
import { AuthGuard } from './portal-fx/service/auth-guard/auth-guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {path: '', component: LoginComponent},
            { path: 'dashboard', component: AppLayoutComponent, 
            canActivate: [AuthGuard],
                children: [
                    { path: '', loadChildren: () => import('./portal-fx/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'profile', 
                    loadChildren: () => import('./portal-fx/components/profile/profile.module').then(m =>m.ProfileModule),
                    canActivateChild: [AuthGuardChild]
                    },
                    { path: 'newprofile', 
                    loadChildren: () => import('./portal-fx/components/newprofile/newprofile.module').then(m =>m.NewprofileModule),
                    canActivateChild: [AuthGuardChild]
                    },
                    { path: 'category', 
                        loadChildren: () => import('./portal-fx/components/categoty/categoty.module').then(m =>m.CategotyModule),
                        canActivateChild: [AuthGuardChild]
                        },
                    { path: 'listusers', 
                    loadChildren: () => import('./portal-fx/components/listusers/listusers.module').then(m =>m.ListusersModule),
                    canActivateChild: [AuthGuardChild]
                    },
                    { path: 'feed',
                    loadChildren: () => import('./portal-fx/components/feed/feed.module').then(m =>m.FeedModule),
                    canActivateChild: [AuthGuardChild]
                    },
                    { path: 'connected',
                    loadChildren: () => import('./portal-fx/components/connected/connected.module').then(m =>m.ConnectedModule),
                    canActivateChild: [AuthGuardChild]
                    },
                    { path: 'programacoes',
                    loadChildren: () => import('./portal-fx/components/programacoes/programacoes.module').then(m =>m.ProgramacoesModule),
                    canActivateChild: [AuthGuardChild]
                    },

                    { path: 'inscricao/:id',
                    loadChildren: () => import('./portal-fx/components/programacoes/inscricao/inscricao.module').then(m =>m.InscricaoModule),
                    canActivateChild: [AuthGuardChild]
                    },
                    { path: 'novoevento',
                        loadChildren: () => import('./portal-fx/components/programacoes/novoevento/novoevento.module').then(m =>m.NovoeventoModule),
                        canActivateChild: [AuthGuardChild]
                    },
                    { path: 'meuseventos',
                        loadChildren: () => import('./portal-fx/components/programacoes/meuevento/meuevento.module').then(m =>m.MeueventoModule),
                        canActivateChild: [AuthGuardChild]
                    },
                    { path: 'Acervo',
                        loadChildren: () => import('./portal-fx/components/library/library.module').then(m =>m.LibraryModule),
                        canActivateChild: [AuthGuardChild]
                    },
                    { path: 'NewAcervo',
                        loadChildren: () => import('./portal-fx/components/library/new-acervo/new-acervo.module').then(m =>m.NewAcervoModule),
                        canActivateChild: [AuthGuardChild]
                    }
                    ,
                    { path: 'AcervoDetails/:id',
                        loadChildren: () => import('./portal-fx/components/library/acervo-details/acervo-details.module').then(m =>m.AcervoDetailsModule),
                        canActivateChild: [AuthGuardChild]
                    }
                    
                ]
            },
            { path: 'auth', loadChildren: () => import('./portal-fx/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'first', loadChildren: () => import('./portal-fx/components/auth/first-access/first-access.module').then(m => m.FirstAccessModule) },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
