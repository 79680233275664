import { OnInit , Component } from '@angular/core';
import { LocalStorageService } from '../portal-fx/utils/storage.service';
import { last } from 'rxjs';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];
   
    constructor(
        private storageService: LocalStorageService
    ) {}

    async ngOnInit() {
       const {permission}:any = await this.storageService.getlocalStorageAuth();
        switch (permission[0]?.name) {
            case 'Gestor':

            this.model = [
                {
                    items: [
                        {
                            label: 'Perfil',
                            icon: 'pi pi-fw pi-user',
                            items: [
                                {
                                    label: 'Meu Perfil',
                                    icon: 'pi pi-fw pi-user',
                                    routerLink: ['profile'],
                                },
                                {
                                    label: 'Cadastrar Novo Perfil',
                                    icon: 'pi pi-fw pi-user-plus',
                                    routerLink: ['newprofile'],
                                },
                                {
                                    label: 'Relação de Conectados',
                                    icon: 'pi pi-fw pi-users',
                                    routerLink: ['listusers'],
                                },
                                {
                                    label: 'Nova categoria',
                                    icon: 'pi pi-building',
                                    routerLink: ['category']
                                }
                            ],
                        },
    
                        {
                            label: 'Eventos',
                            icon: 'pi pi-fw pi-calendar',
                            items: [
                                {
                                    label: 'Programações',
                                    icon: 'pi pi-fw pi-calendar',
                                    routerLink: ['programacoes'],
                                },
    
                                {
                                    label: 'Meus Eventos',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['meuseventos'],
                                },
    
                                {
                                    label: 'Novo Evento',
                                    icon: 'pi pi-fw pi-calendar-plus',
                                    routerLink: ['novoevento'],
                                },
                            ],
                        },
    
                        {
                            label: 'FX Academy',
                            icon: 'pi pi-fw pi-book',
                            items: [
                                {
                                    label: 'Acervo',
                                    icon: 'pi pi-fw pi-file-o',
                                    routerLink: ['Acervo'],
                                },
                                {
                                    label: 'Novo Arquivo',
                                    icon: 'pi pi-fw pi-plus',
                                    routerLink: ['NewAcervo'],
                                },
                            ],
                        },
                        {
                            label: 'Pesquisar Conectados',
                            icon: 'pi pi-fw pi-search',
                            routerLink: ['connected'],
                        },
                    ],
                },
            ];
                
                break;
        case 'Conectado':
            this.model = [
                {
                    items: [
                        {
                            label: 'Perfil',
                            icon: 'pi pi-fw pi-user',
                            items: [
                                {
                                    label: 'Meu Perfil',
                                    icon: 'pi pi-fw pi-user',
                                    routerLink: ['profile'],
                                },
                            ],
                        },
    
                        {
                            label: 'Eventos',
                            icon: 'pi pi-fw pi-calendar',
                            items: [
                                {
                                    label: 'Programações',
                                    icon: 'pi pi-fw pi-calendar',
                                    routerLink: ['programacoes'],
                                },
    
                                {
                                    label: 'Meus Eventos',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['meuseventos'],
                                },
                            ],
                        },
    
                        {
                            label: 'FX Academy',
                            icon: 'pi pi-fw pi-book',
                            items: [
                                {
                                    label: 'Acervo',
                                    icon: 'pi pi-fw pi-file-o',
                                    routerLink: ['Acervo'],
                                },
                            ],
                        },
                        {
                            label: 'Pesquisar Conectados',
                            icon: 'pi pi-fw pi-search',
                            routerLink: ['connected'],
                        },
                    ],
                },
            ];

                break;
        
            default:
                break;
        }

    }
}
