<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="feed">
        <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'fx-logo' : 'fx-logo'}}.png" alt="logo"
        class="h-6rem">
      
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button" (click)="logout()">
            <i class="pi pi-sign-out"></i>
        </button>
    </div>
</div>