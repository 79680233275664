import { Injectable } from "@angular/core";
import { Auth } from "../model/auth";


@Injectable({
    providedIn: 'root'
})

export class LocalStorageService{

    storageAuth(auth:Auth){
       localStorage.setItem('auth', JSON.stringify(auth))
    }

    removeStorage(){
        localStorage.removeItem('auth');
    }

   async getlocalStorageAuth(){
     const auth:Auth = await JSON.parse(localStorage.getItem('auth'));
     return auth;
    }

}