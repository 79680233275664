import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { AuthService } from '../portal-fx/service/auth.service';
import { LocalStorageService } from '../portal-fx/utils/storage.service';
import { Auth } from '../portal-fx/model/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit{

    items!: MenuItem[];
    user: Auth
    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService, 
        public authService:AuthService,
        private readonly localStorage: LocalStorageService,
        private router: Router
    ) { }

   async ngOnInit() {
        this.user = await this.localStorage.getlocalStorageAuth();
    }

    logout(){
        this.authService.logout(this.user.id_tokem).subscribe({
            next: () => {
            this.localStorage.removeStorage();
            this.router.navigate([''])
            },
            complete: () => {},
            error: () => {
            this.localStorage.removeStorage();
            this.router.navigate([''])
            }
        })
    }
}
