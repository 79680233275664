import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Auth } from 'src/app/portal-fx/model/auth';
import { AuthService } from 'src/app/portal-fx/service/auth.service';
import { LocalStorageService } from 'src/app/portal-fx/utils/storage.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styles: [`
        :host ::ng-deep .pi-eye,
        :host ::ng-deep .pi-eye-slash {
            transform:scale(1.6);
            margin-right: 1rem;
            color: var(--primary-color) !important;
        }
    `],
    standalone: false
})
export class LoginComponent implements OnInit{

    valCheck: string[] = ['remember'];

    password!: string;

    authLogin: Auth = {
        email: "", password: "",
        tokem: ''
    };

    loading: boolean = false;

    constructor(
        public   layoutService: LayoutService, 
        private  authSrice:AuthService,
        private  localstorageService: LocalStorageService,
        private  router: Router,
        private  messageService: MessageService
    ) { }

    ngOnInit(): void {}


    async auth(){
        this.loading = true;
        this.authSrice.authFx(this.authLogin).subscribe({ 
            next: (res:Auth) => {
                this.localstorageService.storageAuth(res);
                if(res.first_login){
                    this.router.navigate(['dashboard/profile']) 
                }else{
                this.router.navigate(['dashboard/feed'])
            }
            },
            complete: () => {
                this.authLogin = {email:"", password:""};
                this.localstorageService.getlocalStorageAuth();
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
                this.messageService.add(
                    { 
                    severity: 'warn', 
                    summary: 'Warn',
                    detail: error.status === 401 ?  error.error.message : 'Por favor verificar suas credenciais'
                });
            }
        })
    }


    async logout(){
        this.localstorageService.removeStorage();
    }   
}
