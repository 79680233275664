import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
  } from '@angular/common/http';
  import { catchError, Observable, throwError } from 'rxjs';
import { Auth } from "../../model/auth";
import { Router } from "@angular/router";

@Injectable({
    providedIn:'root'
})

export class InterCeptorTokem implements HttpInterceptor{
  constructor(private router: Router) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const account:Auth = JSON.parse(localStorage.getItem('auth'))
      
        if (account?.isAuth && account.isAuth !== null) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${account.access_token}` }
            });
        }

        /* fazer a correção para guando hover um novo tokem */
       //return next.handle(request);
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
                /*delete storage */
              this.router.navigate(['/']);
            }
            return throwError(error);
          })
        );
        
      } 
} 